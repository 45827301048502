<script>
export default {}
</script>

<template>
  <v-banner two-line color="orange lighten-5" transition="hide-on-leave">
    <v-avatar slot="icon" color="orange" size="40">
      <v-icon color="white"> mdi-human-greeting-variant </v-icon>
    </v-avatar>
    <div class="orange--text font-weight-bold mb-2">Welcome to your member application!</div>
    <div class="orange--text font-weight-medium mb-2">Fill in this form and accept the End User License Agreement (EULA)</div>
    <template v-slot:actions="{ dismiss }" align="center"> <v-spacer /><v-btn medium class="warning" @click="dismiss">OK</v-btn><v-spacer /></template>
  </v-banner>
</template>
